@font-face {
  font-family: 'Akira';
  src: local('Akira'), url('../fonts/akira-demo.otf') format('opentype');
}

html {
  -ms-overflow-style: none !important;
  background-color: #191919;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  padding: 80px 20px;
}

.container {
  align-items: center;
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.row {
  width: 100%;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-67 {
  -ms-flex-preferred-size: 67%;
  flex-basis: 67%;
  max-width: 67%;
}

.col-50 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-33 {
  -ms-flex-preferred-size: 33%;
  flex-basis: 33%;
  max-width: 33%;
}

.col-25 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

h2 {
  font-size: 2.5rem;
  font-family: 'Akira';
  margin-bottom: 0px;
}

h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: #F44853;
  margin: 10px 0px;
  font-weight: 600;
}

h4 {
  font-family: 'Akira';
  color: white;
  font-size: 1.25rem;
}

a {
  text-decoration: none;
  color: #BE0B16;
  transition: color 0.5s ease;
}

a:hover {
  color: #35A2C7;
}

.link-hover {
  transition: color 0.5s ease;
}

.link-hover:hover {
  color: #35A2C7;
}

.button {
  text-decoration: none;
  line-height: 30px;
  color: white;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  background-color: #BE0B16;
  padding: 10px;
  border-radius: 15px;
  transition: background-color 0.5s ease;
}

.button:hover {
  background-color: #35A2C7;
}

a.button:hover {
  color: white;
}

.pr-80 {
  padding-right: 80px;
}

.pr-40 {
  padding-right: 40px;
}

/* cutsom divider */
.custom-shape-divider-bottom-1596551031 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: inherit;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 0;
}

.custom-shape-divider-bottom-1596551031 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 299px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1596551031 .shape-fill {
  fill: #000000;
}